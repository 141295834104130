import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/Layout/Layout";
import Hero from "../components/ContentModules/Hero";

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>404 - Page Not Found</title>
      <meta name="description" content="Page not found" />
    </Helmet>

    <Hero
      module={{
        layout: "simple",
        mainElement: { title: "Sorry", type: "title" },
        noAnimation: true,
      }}
    />
    <div className="container">
      <p>
        Diese Seite ist noch nicht fertig. Bitte gehe zurück zur{" "}
        <Link to="/">Homepage</Link>
      </p>
    </div>
  </Layout>
);

export default NotFoundPage;
